import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

import CloudIndex from "../components/cloud/CloudIndex";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/cloud.png",
};
const canonicalUrl = "https://hasura.io/cloud/";
const HasuraPro = props => (
  <Layout location={props.location}>
    <Seo
      title="Hasura Cloud | A fully managed GraphQL API as a Service"
      description="Hasura Cloud is a fully managed, scalable, highly available, and secure GraphQL API as a service to build global apps fast. Get started in 30 seconds!"
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <CloudIndex />
  </Layout>
);

export default HasuraPro;
