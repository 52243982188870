import React, { Fragment, useRef, useMemo } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { scrollToTop } from "../../utils/helpers";
import { Icon } from '../../globals/icons';
import SubNav from "../solutions/common/SubNav";
import TopBanner from "../solutions/common/TopBanner";
import Features from "../database/sqlServer/Features";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import GetStarted from "../solutions/common/GetStarted";
import { StyledSubTitle1, StyledDesc1 } from "../shared/StyledTypography";
import { removePaddBottom } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

import show from "./images/show.svg";
import database from "./images/database.svg";
import security from "../enterprise/images/security.svg";
import server from "../enterprise/images/server.svg";
import community from "./images/community.svg";
import cms from "./images/cms.svg";
import star3 from "./images/star3.svg";

const StyledWhyhasuraWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 16px;
  padding-bottom: 100px;
  ${mq.between("md", "lg")} {
    padding-bottom: 70px;
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    padding-bottom: 50px;
  }
`;

const topBannerState = {
  title: "Hasura Cloud",
  desc: "Hasura Cloud gives you a fully managed, production ready GraphQL API as a service to help you build modern apps faster. Get started in 30 seconds!",
  btnContent: "Get Started in 30 seconds",
  btnLink: "https://cloud.hasura.io/signup?pg=cloud&plcmt=body&cta=get-started-in-30-seconds&tech=default",
  width75: true,
  videoLink: "https://player.vimeo.com/video/575402291?showinfo=0&rel=0",
  videoTitle: "Getting Started With Hasura",
}

const cachingListState = [
  {
    id: "monitoring-and-tracking",
    imgSrc: show,
    linkContent: "Monitoring and tracking",
    title: "Monitoring and tracking",
    desc: <span>
      <ul className="discUl">
        <li className="disc">Monitor errors, websocket connections, subscriptions, with drill-down into individual operations.</li>
        <li className="disc">Distributed tracing across database, remote schemas, event trigger and auth webhooks, and actions</li>
        <li className="disc">API access to Hasura-generated metrics.</li>
      </ul>
      <Link className="flexCenter" to="/graphql/monitoring/" onClick={scrollToTop}>Read more about Monitoring in Hasura Cloud<Icon className="rightIcon arrow sm" variant="chevron_right" color="sky_80" /></Link>
    </span>,
  },
  {
    id: "query-dynamic-data-caching",
    imgSrc: database,
    linkContent: "Query & Dynamic Data Caching",
    title: "Query & Dynamic Data Caching",
    desc: <span>
      <ul className="discUl">
        <li className="disc">Automatic caching of your shared data: Hasura understands your data models and your end-user authorization rules.</li>
        <li className="disc">Cache query plans at the GraphQL and at the database level with Hasura’s advanced query engine.</li>
        <li className="disc">Blazing fast performance.</li>
      </ul>
      <Link className="flexCenter" to="/graphql/caching/" onClick={scrollToTop}>Read more about Query Caching<Icon className="rightIcon arrow sm" variant="chevron_right" color="sky_80" /></Link>
    </span>,
  },
  {
    id: "rate-limiting",
    imgSrc: security,
    linkContent: "Rate Limiting",
    title: "Rate Limiting",
    desc: <span>
      <ul className="discUl">
        <li className="disc">Prevent abuse / DoS attacks on your GraphQL API.</li>
        <li className="disc">Manage QoS with user identity & session aware configuration.</li>
        <li className="disc">Set limits based on-
          <ul className="discUl">
            <li className="disc">No. of requests per minute/hour</li>
            <li className="disc">Query depth</li>
          </ul>
        </li>
      </ul>
    </span>,
  },
  {
    id: "regression-tests",
    imgSrc: server,
    linkContent: "Regression Tests",
    title: "Regression Tests",
    desc: <span>
      <ul className="discUl">
        <li className="disc">Prevent breaking changes & regressions automatically by replaying production traffic on dev/staging to capture schema & configuration changes.</li>
        <li className="disc">Automatically create a regression suite using production traffic.</li>
        <li className="disc">Replay operations from the regression suite in dev & staging.</li>
      </ul>
    </span>,
  },
  {
    id: "team-management",
    imgSrc: community,
    linkContent: "Team Management",
    title: "Team Management",
    desc: <span>
      <ul className="discUl">
        <li className="disc">Member specific login to Hasura project with granular privileges.
          <ul className="discUl">
            <li className="disc">Run GraphQL queries</li>
            <li className="disc">View analytics & metrics</li>
            <li className="disc">Manage team members</li>
          </ul>
        </li>
        <li className="disc">SSO integration available on request.</li>
      </ul>
    </span>,
  },
  {
    id: "allow-listing",
    imgSrc: cms,
    linkContent: "Allow-Listing",
    title: "Allow-Listing",
    desc: <span>
      <ul className="discUl pb8">
        <li className="disc">Prevent scraping & abuse of your GraphQL API by allow-listing GraphQL operations actually used by your app.</li>
        <li className="disc">Automatically build an allow-list by capturing operations during CI.</li>
        <li className="disc">Enable warn mode to only log unknown operations in production without preventing their execution.</li>
      </ul>
      <Link className="flexCenter" to="/graphql/security/" onClick={scrollToTop}>Read more about GraphQL Security<Icon className="rightIcon arrow sm" variant="chevron_right" color="sky_80" /></Link>
    </span>,
  },
  {
    id: "read-replicas",
    imgSrc: star3,
    linkContent: "Read Replicas",
    title: "Read Replicas",
    desc: <span>
      <ul className="discUl">
        <li className="disc">Native Read Replica support for enhanced performance and scalability.</li>
        <li className="disc">Automatic routing for queries, subscriptions and mutations across master and read-replicas.</li>
      </ul>
    </span>,
  },
]

const CloudIndex = () => {
  const featuresRef = useRef(null);
  const monitoringAndTrackingRef = useRef(null);
  const queryDynamicDataCachingRef = useRef(null);
  const rateLimitingRef = useRef(null);
  const regressionTestsRef = useRef(null);
  const teamManagementRef = useRef(null);
  const allowListingRef = useRef(null);
  const readReplicasRef = useRef(null);

  const sectionRefs = useMemo(
    () => [
      { name: "Features", section: "features", ref: featuresRef },
    ],
    []
  );

  const openSourceSection = {
    "monitoring-and-tracking": { section: "monitoring-and-tracking", ref: monitoringAndTrackingRef },
    "query-dynamic-data-caching": { section: "query-dynamic-data-caching", ref: queryDynamicDataCachingRef },
    "rate-limiting": { section: "rate-limiting", ref: rateLimitingRef },
    "regression-tests": { section: "regression-tests", ref: regressionTestsRef },
    "team-management": { section: "team-management", ref: teamManagementRef },
    "allow-listing": { section: "allow-listing", ref: allowListingRef },
    "read-replicas": { section: "read-replicas", ref: readReplicasRef },
  }

  return (
    <>
      <SubNav
        sectionRefs={sectionRefs}
        pageName="Hasura Cloud"
        pagePath="/cloud/"
        currentDropDownMenu="products"
        subNavBtnLink="https://cloud.hasura.io/signup?pg=caching&plcmt=sub-header&cta=get-started-now&tech=default"
      />
      <TopBanner topBannerState={topBannerState} paddBottom />
      <StyledContainerWrapper>
        <StyledWhyhasuraWrapper>
          <StyledSubTitle1>Why Hasura Cloud?</StyledSubTitle1>
          <StyledDesc1>
            Hasura Cloud is a fully re-engineered version of Hasura's popular open source graphql engine that gives you a scalable, highly available, globally distributed, secure GraphQL API over your data sources.<br/><br/>

            Hasura Cloud takes care of all the performance, security & reliability requirements of your API layer by adding a powerful suite of production-ready features from day one.
          </StyledDesc1>
        </StyledWhyhasuraWrapper>
      </StyledContainerWrapper>
      <div  id="features" ref={featuresRef}>
        <Features
          title="Features"
          sections = {openSourceSection}
          tabListState = {cachingListState}
        />
      </div>
      <StyledSectionWrapper css={removePaddBottom}>
        <GetStarted
          title="Ready to get started with Hasura?"
          btnContent="Try on Hasura Cloud"
          btnLink="https://cloud.hasura.io/signup?pg=opensource&plcmt=body&cta=try-on-hasura-cloud&tech=default"
        />
      </StyledSectionWrapper>
    </>
  );
};

export default CloudIndex;
